import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link, graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

const imgSize = {
  maxHeight: '387px',
  maxWidth: '565px',
};

/**
 * CTA module component
 * @component
 *
 * @param   {string} background   - Background circle color gradient
 * @param   {string} button       - CTA button background color
 * @param   {string} color        - Title accent color
 * @param   {string} solution     - Type of portal; determines the copy and image loaded
 * @returns {component}           - <CTA background={string/css-module-class} button={string/css-module-class} color={string/css-module-class} solution={string} />
 */

const CTA = ({ background, button, color, solution }) => {
  const { mp, ms, opus, ps } = useStaticQuery(graphql`
    query {
      mp: contentfulAsset(title: { eq: "cta-member-profile" }) {
        fluid(maxWidth: 600, quality: 85, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      ms: contentfulAsset(title: { eq: "cta-member-services" }) {
        fluid(maxWidth: 600, quality: 85, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      ps: contentfulAsset(title: { eq: "cta-provider-spotlight" }) {
        fluid(maxWidth: 600, quality: 85, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      opus: contentfulAsset(title: { eq: "opus" }) {
        file {
          url
        }
      }
    }
  `);

  // determine with solution was passed
  const provider = solution.match(/provider/g);
  const profile = solution.match(/profile/g);

  // solution specific image props
  const profileImg = {
    alt: 'Member Profile portal.',
    src: mp.fluid,
  };
  const servicesImg = {
    alt: 'Member Services portal.',
    src: ms.fluid,
  };
  const providerImg = {
    alt: 'Provider Spotlight portal.',
    src: ps.fluid,
  };

  return (
    <section
      id="cta"
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.ctaWrap,
        theme.shapeBgGrey
      )}
    >
      <div
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyCenter,
          base.mb2,
          base.ml1,
          base.mr1,
          base.mt2,
          base.w100,
          main.ctaContainer
        )}
      >
        <div
          className={cx(
            base.flex,
            base.itemsCenter,
            base.justifyCenter,
            base.relative,
            base.w100,
            main.ctaImgContainer
          )}
        >
          <div
            className={cx(
              background,
              base.absolute,
              base.bottom0,
              base.top0,
              main.ctaCircle
            )}
          />
          <Img
            fluid={
              provider
                ? providerImg.src
                : profile
                ? profileImg.src
                : servicesImg.src
            }
            alt={
              provider
                ? providerImg.alt
                : profile
                ? profileImg.alt
                : servicesImg.alt
            }
            style={imgSize}
            imgStyle={imgSize}
          />
        </div>
        <div
          className={cx(
            base.flex,
            base.flexColumn,
            base.itemsStart,
            base.justifyStart,
            base.w100,
            main.ctaCopyContainer,
            theme.colorDark
          )}
        >
          <p
            className={cx(
              base.mb3,
              base.mt0,
              base.w100,
              theme.colorDark,
              theme.title2
            )}
          >
            OPUS
          </p>
          <div className={cx(button, base.mb4, main.ctaDivider)} />
          {provider ? (
            <p
              className={cx(
                color,
                base.mb3,
                base.mt0,
                base.pb2,
                base.pt2,
                base.w100,
                main.subHeading
              )}
            >
              Actionable provider insights that{' '}
              <span className={theme.colorDark}>supercharge</span> performance.
            </p>
          ) : (
            <p
              className={cx(
                base.mb3,
                base.mt0,
                base.pb2,
                base.pt2,
                base.w100,
                main.subHeading,
                theme.colorDark
              )}
            >
              An actionable member summary that
              <span className={color}> supercharges </span> engagement.
            </p>
          )}
          {provider ? (
            <p
              className={cx(base.mt2, base.w100, main.stCopy, theme.colorDark)}
            >
              Provider Spotlight delivers an at-a-glance view of a
              provider&apos;s cost, utilization, and quality performance
              enabling the plan, provider, and member to work together to
              improve outcomes.
            </p>
          ) : (
            <p
              className={cx(base.mt2, base.w100, main.stCopy, theme.colorDark)}
            >
              Our
              {opus ? (
                <img
                  src={opus.file.url}
                  alt=""
                  aria-hidden="true"
                  className={main.ctaIcon}
                />
              ) : null}
              <span className={main.visuallyHidden}>Opus</span> Member{' '}
              {profile ? 'Profile' : 'Services'} delivers an at-a-glance view of
              a member&apos;s historical experiences and predicted future
              behavior, enabling health plans to rapidly act upon critical
              intelligence.
            </p>
          )}
        </div>
      </div>
      <Link
        to="/contact"
        className={cx(
          button,
          main.button,
          main.ctaButton,
          main.mt30,
          theme.colorWhite,
          theme.title2
        )}
      >
        Request demo
      </Link>
    </section>
  );
};

CTA.propTypes = {
  background: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  solution: PropTypes.string.isRequired,
};

export default CTA;
