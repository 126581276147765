import React from 'react';
import PropTypes from 'prop-types';

import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

import CTA from '../../components/ctaContainer';
import Hero from '../../components/provider-management/providerManagementHero';
import Layout from '../../components/layout';
import PMStatements from '../../components/provider-management/providerManagementStatements';

const pageDescription =
  'Informed referrals. Superior network sculpting. Deep insights that drive plan, provider, and member collaboration.';

/**
 * Provider Management page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <ProviderManagement location={string} />
 */

const ProviderManagement = ({ location }) => (
  <Layout
    pageTitle="Provider Management"
    pageDescription={pageDescription}
    location={location}
  >
    <Hero />
    <PMStatements />
    <CTA
      background={main.colorGradientDark}
      button={theme.dpBtnDark}
      color={theme.colorTextGrey}
      solution="provider"
    />
  </Layout>
);

ProviderManagement.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default ProviderManagement;
