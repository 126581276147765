import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Statements from '../statements';

/**
 * Provider Management page Statements component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <PMStatements />
 */

const PMStatements = () => {
  const {
    contentfulProviderManagement: {
      insightAlt,
      insightContent,
      insightFlipped,
      insightImage,
      insightTitle,
      networkAlt,
      networkContent,
      networkFlipped,
      networkImage,
      networkTitle,
      referralsAlt,
      referralsContent,
      referralsFlipped,
      referralsImage,
      referralsTitle,
      shiftAlt,
      shiftContent,
      shiftFlipped,
      shiftImage,
      shiftTitle,
    },
  } = useStaticQuery(graphql`
    query providerManagementStatements {
      contentfulProviderManagement {
        shiftAlt
        shiftContent {
          childMarkdownRemark {
            html
          }
        }
        shiftFlipped
        shiftImage {
          fluid(maxWidth: 450, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        shiftTitle
        referralsAlt
        referralsContent {
          childMarkdownRemark {
            html
          }
        }
        referralsFlipped
        referralsImage {
          fluid(maxWidth: 450, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        referralsTitle
        networkAlt
        networkContent {
          childMarkdownRemark {
            html
          }
        }
        networkFlipped
        networkImage {
          fluid(maxWidth: 450, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        networkTitle
        insightAlt
        insightContent {
          childMarkdownRemark {
            html
          }
        }
        insightFlipped
        insightImage {
          fluid(maxWidth: 450, quality: 80, resizingBehavior: FILL) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        insightTitle
      }
    }
  `);

  const statements = [
    {
      alt: shiftAlt,
      copy: shiftContent.childMarkdownRemark.html,
      flipped: shiftFlipped,
      heading: shiftTitle,
      image: shiftImage.fluid,
    },
    {
      alt: referralsAlt,
      copy: referralsContent.childMarkdownRemark.html,
      flipped: referralsFlipped,
      heading: referralsTitle,
      image: referralsImage.fluid,
    },
    {
      alt: networkAlt,
      copy: networkContent.childMarkdownRemark.html,
      flipped: networkFlipped,
      heading: networkTitle,
      image: networkImage.fluid,
    },
    {
      alt: insightAlt,
      copy: insightContent.childMarkdownRemark.html,
      flipped: insightFlipped,
      heading: insightTitle,
      image: insightImage.fluid,
    },
  ];

  return <Statements sections={statements} />;
};

export default PMStatements;
