import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import cx from 'classnames';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';

import CleanHTML from './cleanHTML';

const copyImg = {
  maxHeight: '360px',
  maxWidth: '360px',
  objectFit: 'contain',
};

const copyImgLg = {
  maxWidth: '480px',
  objectFit: 'contain',
};

const copyImgXl = {
  maxWidth: '600px',
  objectFit: 'contain',
};

/**
 * Statements component.
 * @component
 *
 * @param   {array} sections - List of objects containing section data; title, copy, img
 * @returns {component}      - <Statements sections={array} />
 */

const Statements = ({ sections }) => (
  <section
    className={cx(
      base.flex,
      base.flexColumn,
      base.itemsCenter,
      base.justifyCenter,
      base.w100,
      main.mainWrapper
    )}
  >
    {/* map over array of sections and extract data. data sourced from Contentful query */}
    {sections.map((items, i) => (
      <div
        key={items.image.src}
        className={cx(
          base.flex,
          base.itemsCenter,
          base.justifyCenter,
          base.w100,
          main.dataWrapper,
          {
            [main.dataItemBg]: items.bg,
          }
        )}
      >
        <div
          className={cx(
            base.flex,
            {
              [base.flexWrap]: items.flipped === false,
              [base.flexWrapReverse]: items.flipped,
            },
            base.itemsCenter,
            base.justifyBetween,
            base.w100,
            {
              [main.dataItem]: !items.large && !items.xl,
              [main.dataItemLg]: items.large,
              [main.dataItemXxl]: items.xl,
            }
          )}
        >
          <Img
            fluid={items.image}
            alt={items.alt}
            style={items.lg ? copyImgLg : items.xl ? copyImgXl : copyImg}
            imgStyle={items.lg ? copyImgLg : items.xl ? copyImgXl : copyImg}
            className={cx(base.mb2, { [base.order2]: items.flipped })}
          />
          <div
            className={cx(base.ma2, base.w100, {
              [base.order1]: items.flipped,
              [main.imgCopyWrap]: !items.large && !items.xl,
              [main.imgCopyWrapLg]: items.large,
              [main.imgCopyWrapLg]: items.xl,
            })}
          >
            <p className={cx(base.mb2, base.mt0, main.topHeading)}>
              {items.heading}
            </p>
            <p className={cx(base.mb2, base.mt0, main.subHeading)}>
              {items.subheading}
            </p>
            <div
              className={cx(main.statementsContent, main.stCopy, {
                [main.statementsContentFirst]: !items.even && i !== 2,
              })}
            >
              <CleanHTML html={items.copy} />
              {items.cta && <CleanHTML html={items.cta} />}
            </div>
          </div>
        </div>
      </div>
    ))}
  </section>
);

Statements.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Statements;
